import React from "react";
import { Provider } from "react-redux";
import { create } from "jss";
import jssExtend from "jss-plugin-extend";

import {
  MuiThemeProvider,
  createGenerateClassName,
  StylesProvider,
  jssPreset,
} from "@material-ui/core/styles";

import createStore from "./src/state/store";
import theme from "./src/styles/theme";

// remove the JSS style tag generated on the server to avoid conflicts with the one added on the client
export const onInitialClientRender = function () {
  const ssStyles = window.document.getElementById("server-side-jss");
  if (ssStyles) {
    ssStyles.parentNode.removeChild(ssStyles);
  }
};

// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }, options) => {
  const generateClassName = createGenerateClassName();
  const store = createStore();

  // Configure JSS
  const jss = create({ plugins: [jssExtend(), ...jssPreset().plugins] });

  return (
    <Provider store={store}>
      <StylesProvider jss={jss} generateClassName={generateClassName}>
        <MuiThemeProvider theme={theme}>{element}</MuiThemeProvider>
      </StylesProvider>
    </Provider>
  );
};

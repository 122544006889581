/* eslint-disable new-cap */
import { createMuiTheme } from "@material-ui/core/styles";
import Color from "color";

const colors = require("./colors");

const sizes = {
  SM: 576,
  M: 768, // M: 600,
  L: 992, // L: 1024
  XL: 1200,
};

const theme = createMuiTheme({
  base: {
    colors: {
      background: colors.bg,
      text: colors.white,
      link: colors.lightGray,
      linkHover: Color(colors.lightGray).lighten(0.1).string(),
      accent: colors.accent,
      scrollbar: Color(colors.lightGray).darken(0.1).string(),
    },
    fonts: {
      unstyledFamily: `Arial, sans-serif`,
      styledFamily: "Open Sans",
      styledFonts: "300,400,600",
    },
  },
  home: {
    colors: {
      title: colors.gray,
    },
  },
  main: {
    colors: {
      background: "transparent",
      title: colors.white,
      content: colors.white,
      contentHeading: colors.white,
      blockquoteFrame: colors.lightGray,
      link: colors.lightGray,
      linkHover: colors.gray,
    },
    sizes: {
      articleMaxWidth: "800px",
    },
    fonts: {
      title: {
        size: 1.9,
        sizeM: 2.5,
        sizeL: 2.7,
        weight: 600,
        lineHeight: 1.1,
      },
      content: {
        size: 1.0,
        sizeM: 1.15,
        sizeL: 1.1,
        lineHeight: 1.6,
      },
      contentHeading: {
        h2Size: 1.5,
        h3Size: 1.3,
        weight: 600,
        lineHeight: 1.3,
      },
    },
  },
  footer: {
    colors: {
      text: Color(colors.gray).lighten(0.5).string(),
      link: colors.accent,
      linkHover: Color(colors.accent).lighten(0.2).string(),
    },
    fonts: {
      footnote: {
        size: 0.8,
        lineHeight: 1.4,
      },
    },
  },

  mediaQueryTresholds: { ...sizes },

  palette: {
    primary: {
      main: "#709425",
    },
  },

  typography: {
    fontFamily: `Arial, sans-serif`,
    fontSize: 16,
  },

  pallete: {
    action: {
      hover: "rgba(0, 0, 0, 0.01)",
    },
  },

  // custom
  mainButtonsTopPosition: {
    position: "absolute",
    margin: "28px 0 0",
    top: "calc(100vh - 114px)",

    // needs to be here to keep the order of the rules when extending
    ".sidebar &": {},

    [`@media (min-width: ${sizes.M}px)`]: {
      top: "calc(100vh - 124px)",
    },

    [`@media (min-width: ${sizes.L}px)`]: {
      top: "calc(100vh - 134px)",
    },
  },
});

export default theme;

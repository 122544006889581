module.exports = {
  bg: "rgb(3,3,3)",
  white: "#ffffff",
  accent: "#709425",
  bright: "#ffffff",
  dark: "#333333",
  gray: "#555555",
  lightGray: "#bbbbbb",
  superLightGray: "#dedede",
};
